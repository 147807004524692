interface AuthorizationRequiredProps {
  message?: string;
  className?: string;
}

export const AuthorizationRequired = ({
  message = "認証が必要です", // Default message in Japanese
  className = "",
}: AuthorizationRequiredProps) => {
  return (
    <div
      className={`min-h-screen flex items-center justify-center bg-gray-50 ${className}`}
    >
      <div className="max-w-md w-full space-y-8 p-8">
        <div>
          <h1 className="mt-6 text-center text-3xl font-bold text-gray-900">
            401
          </h1>
          <p className="mt-2 text-center text-sm text-gray-600">{message}</p>
        </div>
        {/* Optional: Add a "Go Back" button */}
        <div className="mt-4 text-center">
          <button
            onClick={() => window.history.back()}
            className="text-sm text-blue-600 hover:text-blue-500"
          >
            前のページに戻る
          </button>
        </div>
      </div>
    </div>
  );
};
