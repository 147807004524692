import type { MetaFunction } from "@remix-run/cloudflare";
import Header from "~/components/Header";
import Footer from "~/components/Footer";
import TopicPath from "~/components/TopicPath";
import Contact2 from "~/components/top/Contact2";
import MediumButton from "~/components/MediumButton";
import { useScrollToAnchor } from "~/hooks";
import { useEffect } from "react";
import { useRouteError, isRouteErrorResponse } from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { AuthorizationRequired } from "./AuthorizationRequired";

export const errorPageMeta: MetaFunction = ({ error }) => {
  const status = isRouteErrorResponse(error) ? error.status : 500;
  const title =
    status === 404
      ? "404 Not Found | 飲食店の正社員求人・転職はエフジョブ求人"
      : "500 Server Error | 飲食店の正社員求人・転職はエフジョブ求人";
  return [
    { title },
    {
      name: "description",
      content: "理想の転職を飲食業界のプロと一緒に実現するエフジョブ求人",
    },
    {
      property: "og:title",
      content: title,
    },
    {
      property: "og:description",
      content: "理想の転職を飲食業界のプロと一緒に実現するエフジョブ求人",
    },
    {
      property: "og:type",
      content: "article",
    },
    {
      property: "og:image",
      content: "https://public.fjob-kyujin.com/public/ogimage.png",
    },
    {
      property: "og:url",
      content: "https://fjob-kyujin.com/",
    },
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
  ];
};

interface ErrorPageLayoutProps {
  title: string;
  description: string;
  children?: React.ReactNode;
}

const ErrorPageLayout = ({
  title,
  description,
  children,
}: ErrorPageLayoutProps) => {
  const { scrollToAnchor } = useScrollToAnchor({ smooth: true });

  useEffect(() => {
    scrollToAnchor();
  }, [scrollToAnchor]);

  const linkToTop = () => {
    location.href = "/";
  };

  return (
    <div>
      <Header />
      <TopicPath>
        <li>
          <a href="/">TOP</a>
        </li>
        <li>{title}</li>
      </TopicPath>
      <main>
        <div className="px-6 py-20 md:px-7 md:py-[120px]">
          <div className="md:max-w-[1040px] md:mx-auto md:text-center">
            <h2 className="mb-4 text-2xl font-bold leading-normal md:text-[32px] md:leading-normal">
              {title}
            </h2>
            <p className="text-sm leading-[1.8] md:text-base md:leading-normal">
              {description}
            </p>
            {children}
            <div className="mt-10 text-center [&_button]:w-[280px]">
              <MediumButton
                text="TOPページに戻る"
                color="white"
                handler={linkToTop}
              />
            </div>
          </div>
        </div>
        <Contact2 />
      </main>
      <Footer />
    </div>
  );
};

export const RootErrorBoundary = () => {
  const error = useRouteError();
  const isProduction = process.env.NODE_ENV === "production";

  if (isRouteErrorResponse(error)) {
    switch (error.status) {
      case 401:
        return <AuthorizationRequired />;

      case 404:
        return (
          <ErrorPageLayout
            title="ページが見つかりません"
            description="お探しのページは移動、または削除された可能性がございます。"
          />
        );

      default:
        captureRemixErrorBoundaryError(error);
        return (
          <ErrorPageLayout
            title="サーバーエラーが発生しました"
            description="申し訳ありませんが、システムに問題が発生しました。しばらく時間をおいてから再度お試しください。"
          >
            {!isProduction && (
              <div className="mt-4 p-4 bg-gray-100 rounded-md text-left">
                <pre className="text-xs text-gray-600 overflow-auto">
                  {error.data?.message || error.statusText}
                </pre>
              </div>
            )}
          </ErrorPageLayout>
        );
    }
  }

  captureRemixErrorBoundaryError(error);
  return (
    <ErrorPageLayout
      title="サーバーエラーが発生しました"
      description="申し訳ありませんが、システムに問題が発生しました。しばらく時間をおいてから再度お試しください。"
    />
  );
};
